<template>
    <v-container
        fluid
        style="max-width: 550px; align: center; justify-content: center;"
        align-items="center"
    >
        <v-form v-model="contactInformationValid">
            <v-row class="row">
                <v-col
                    cols="8"
                    class="titles"
                >
                    <h1>Kontaktoplysninger</h1>
                </v-col>
                <v-col
                    cols="10"
                >
                    <v-text-field
                        v-model="name"
                        width="50%"
                        label="Navn"
                        disabled
                    />
                </v-col>
            </v-row>
            <v-row class="row">
                <v-col cols="6">
                    <v-text-field
                        v-model="userEmail"
                        label="Email"
                        :rules="globalEmailValidator(userEmail, true)"
                    />
                </v-col>
                <v-col cols="4">
                    <v-text-field
                        v-model="userPhone"
                        label="Telefonnummer"
                        variant="outlined"
                        :rules="globalPhoneNumberValidator(userPhone, true)"
                    />
                </v-col>
            </v-row>
        </v-form>

        <v-btn 
            color="primary"
            :disabled="!contactInformationValid || ((userEmail === null || userEmail === '') && (userPhone == null || userPhone === ''))"
            @click="saveContactInfo()"
        >
            Gem kontaktoplysninger
        </v-btn>
        <v-divider class="divider" />
        <v-row
            v-if="!userHasInformationDetails"
            align-items="center"
            style="max-width: 550px;
            margin: auto;"
        >
            <v-col cols="12">
                For at kunne tilmelde udvidet åbningstid, skal du udfylde og gemme enten email eller telefonnummer.
            </v-col>
        </v-row>
        <v-row 
            v-if="allowRegistrationNumberRelation && userHasInformationDetails"
            class="row"
        >
            <v-col
                cols="12"
                class="titles"
            >
                <h1>Køretøjer</h1>
            </v-col>
            <v-col
                v-if="registrations.length > 0"
                cols="12"
                md="6"
            >
                <v-list :items="registrations">
                    <v-list-item
                        v-for="(item, index) in registrations"
                        :key="index"
                        style="padding: 0px;"
                    >
                        <v-icon style="padding-right: 10px">
                            mdi-car-back
                        </v-icon>
                        <v-list-item-content
                            class="list-item"
                        >
                            {{ item.registrationNumber.toUpperCase() }}
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn
                                icon
                                @click="clickDeleteRelation(item)"
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-col>
            <v-col cols="12">
                <v-btn
                    color="#75ad40"
                    @click="addRegistrationNumber = true"
                >
                    <v-icon
                        size="large"
                        color="white"
                    >
                        mdi-plus
                    </v-icon>  
                    
                    <span style="color: white;">
                        Tilføj køretøj
                    </span>
                </v-btn>
            </v-col>
            <v-col cols="12">
                Tryk på "Tilføj køretøj" for at tilføje et registreringsnummer på et køretøj, som må kunne åbne portene ved genbrugspladserne.
            </v-col>
        </v-row>
        <v-divider 
            v-if="allowPhoneNumberRelation"
            class="divider" 
        />
        <v-row 
            v-if="allowPhoneNumberRelation && userHasInformationDetails"
            class="row"
        >
            <v-col
                cols="12"
                md="6"
                class="titles"
            >
                <h1>Mobilnumre</h1>
            </v-col>
            <v-col
                v-if="phoneNumbers.length > 0"
                cols="12"
                md="6"
            >
                <v-list :items="phoneNumbers">
                    <v-list-item
                        v-for="(item, index) in phoneNumbers"
                        :key="index"
                        style="padding: 0px;"
                    >
                        <v-icon style="padding-right: 10px">
                            mdi-cellphone-text
                        </v-icon>
                        <v-list-item-content
                            class="list-item"
                        >
                            {{ item.registrationNumber }}
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn
                                icon
                                @click="clickDeleteRelation(item)"
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-col>
            <v-col cols="12">
                <v-btn
                    color="#75ad40"
                    
                    @click="addPhoneNumber = true"
                >
                    <v-icon
                        size="large"
                        color="white"
                    >
                        mdi-plus
                    </v-icon> <span style="color: white;">
                        Tilføj mobilnummer

                    </span>
                </v-btn>
            </v-col>
            <v-col cols="12">
                Tryk på "Tilføj mobilnummer" for at tilføje et nummer, som må kunne åbne portene ved genbrugspladserne.
            </v-col>
        </v-row>
    
      
        <add-phone-number-dialog 
            v-if="addPhoneNumber"
            @unilockerror="unilockerror = true"
            @close="dataChanged()"
        />
        <add-registration-number-dialog 
            v-if="addRegistrationNumber"
            @unilockerror="unilockerror = true"
            @close="dataChanged()"
        />
        <v-dialog
            v-model="dialogDelete"
            max-width="500px"
        >
            <v-card>
                <v-card-title class="py-1">
                    Slet
                </v-card-title>
                <v-card-text>
                    Vil du slette:
                    <br>
                    <v-text-field
                        v-model="relationToDelete.registrationNumber"
                        :label="relationToDelete.relationType == 0 ? 'Nummerplade' : 'Mobilnummer'"
                        readonly
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        @click="dialogDelete = false"
                    >
                        Annuller
                    </v-btn>
                    <v-btn
                        :loading="working"
                        @click="deleteRelation(relationToDelete.id)"
                    >
                        Slet
                    </v-btn>
                    <v-spacer />
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="unilockerror"
            max-width="500px"
        >
            <v-card>
                <v-card-title>Fejl med adgangskontrol</v-card-title>
                <v-card-text>
                    Der skete en uventet fejl ved tilmelding af adgangskontrol. Prøv venligst igen senere eller kontakt supporten hvis problemet fortsætter.
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-divider class="divider" />

        <v-row
            align-items="center"
            style="max-width: 550px; margin: auto;"
        >
            <v-col cols="9" />
            <v-col cols="3">
                <v-btn
                    size="small"
                    width="100px"
                    class="ma-2"
                    @click="HandleLogOut()"
                >
                    Log ud
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'


export default {
    components: {
        addPhoneNumberDialog: () => import('@/views/registration/AddPhoneNumberDialog.vue'),
        addRegistrationNumberDialog: () => import('@/views/registration/AddRegistrationNumberDialog.vue')
    },
    data() {
        return {
            name: '',
            addPhoneNumber: false,
            addRegistrationNumber: false,
            registrations: [],
            phoneNumbers: [],
            dialogDelete: false,
            relationToDelete: {},
            working: true,
            unilockerror: false,

            userEmail: '',
            userPhone: '',

            allowRegistrationNumberRelation: false,
            allowPhoneNumberRelation: false,

            userHasInformationDetails: false,
            contactInformationValid: false,
        }
    },
    computed: {
        ...mapGetters({
            GetSystemSettingStore: 'GetSystemSettingStore'
        })
    },
    created() {    
        if(localStorage.getItem('errorcode')){
            localStorage.setItem('error', 'Du skal være logget ind for at se denne side')
            this.$router.push({ name: 'Error' })
        }

        let systemSetting = this.GetSystemSettingStore(17)
        if(systemSetting == null){
            this.GetSystemSetting({type: 17}).then(result => {
                systemSetting = result.setting
                this.allowRegistrationNumberRelation = systemSetting.allowRegistrationNumberRelation
                this.allowPhoneNumberRelation = systemSetting.allowPhoneNumberRelation
            })
        }else{
            this.allowRegistrationNumberRelation = systemSetting.allowRegistrationNumberRelation
            this.allowPhoneNumberRelation = systemSetting.allowPhoneNumberRelation
        }

        this.GetRegistrations()

        this.name = localStorage.getItem('customerName')
        this.userEmail = localStorage.getItem('customerEmail')
        this.userPhone = localStorage.getItem('customerPhoneNumber')

        this.userHasInformationDetails = (localStorage.getItem('customerEmail') !== null && localStorage.getItem('customerEmail') !== '') || (localStorage.getItem('customerPhoneNumber') !== null && localStorage.getItem('customerPhoneNumber') !== '')
 
        this.working = false
    },    
    methods: {
        HandleLogOut(){
            this.$store.dispatch('LogOut')
            this.$router.push({ name: 'Login' })
        },
        dataChanged(){
            this.addPhoneNumber = false
            this.addRegistrationNumber = false
            this.GetRegistrations()
        },
        GetRegistrations() {
            this.phoneNumbers = []
            this.registrations = []
            this.FetchCustomerPrivateRegistrationRelationList().then(result => {
                result.map(item => {
                    item.relationType == 0 ? this.registrations.push(item) : this.phoneNumbers.push(item)
                })
            })
        },
        deleteRelation(id){
            this.working = true
            this.FetchDeleteCustomerPrivateRegistrationRelation({
                customerRegistrationRelationId: id
            }).then(() => {
                this.working = false
                this.dialogDelete = false
                this.GetRegistrations()
            })
        },
        saveContactInfo(){
            this.savingContactInformation = true
            this.UpdatePrivateUserDetails({
                email: this.userEmail,
                phoneNumber: this.userPhone
            }).then(result => {
                localStorage.setItem('customerEmail', this.userEmail)
                localStorage.setItem('customerPhoneNumber', this.userPhone)
                if(result.success){
                    this.savingContactInformation = false

                    this.userHasInformationDetails = this.userEmail != null || this.userPhone != null
                }
            })
        },
        clickDeleteRelation(item){
            this.relationToDelete = item
            this.dialogDelete = true
        },
        ...mapActions({
            FetchCustomerPrivateRegistrationRelationList: 'FetchCustomerPrivateRegistrationRelationList',
            FetchDeleteCustomerPrivateRegistrationRelation: 'FetchDeleteCustomerPrivateRegistrationRelation',
            UpdatePrivateUserDetails: 'UpdatePrivateUserDetails',
            GetCustomerPrivateByCriiptoIdentification: 'GetCustomerPrivateByCriiptoIdentification',
            GetSystemSetting: 'GetSystemSetting'
        })
        
    }
}
</script>

<style scoped>
.row {
    margin-top: 10px;
    padding: 0px;
}
.divider {
    margin-top: 20px;
}
.list-item {
    padding: 0px;
    padding-bottom: 25px;
    margin: auto;
    height: 20px;
    border-bottom: 1px solid black;
    border-spacing: 15px 50px;
    font-weight: normal;
}
.titles {
    font-weight: bold;
    margin: 0px;
    padding: 0px;
    margin-left: 10px;
    margin-bottom: -5px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>